/*

TemplateMo 571 Hexashop

https://templatemo.com/tm-571-hexashop

*/

/* ---------------------------------------------
Table of contents
------------------------------------------------
01. font & reset css
02. reset
03. global styles
04. header
05. banner
06. features
07. testimonials
08. contact
09. footer
10. preloader
11. search
12. portfolio

--------------------------------------------- */
/* 
---------------------------------------------
font & reset css
--------------------------------------------- 
*/
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
footer,
figcaption {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

.clearfix:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.clearfix {
    display: inline-block;
}

html[xmlns] .clearfix {
    display: block;
}

* html .clearfix {
    height: 1%;
}

ul,
li {
    padding: 0;
    margin: 0;
    list-style: none;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
    display: block;
}

* {
    box-sizing: border-box;
}

html,
body {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    background-color: #fff;
    font-size: 16px;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0px;
    margin-bottom: 0px;
}

ul {
    margin-bottom: 0px;
}

p {
    font-size: 14px;
    line-height: 25px;
    color: #2a2a2a;
}

/* 
---------------------------------------------
global styles
--------------------------------------------- 
*/
html,
body {
    background: #fff;
    font-family: 'Poppins', sans-serif;
}

::selection {
    background: #2a2a2a;
    color: #fff;
}

::-moz-selection {
    background: #2a2a2a;
    color: #fff;
}

@media (max-width: 991px) {

    html,
    body {
        overflow-x: hidden;
    }

    .mobile-top-fix {
        margin-top: 30px;
        margin-bottom: 0px;
    }

    .mobile-bottom-fix {
        margin-bottom: 30px;
    }

    .mobile-bottom-fix-big {
        margin-bottom: 60px;
    }
}

.main-border-button a {
    font-size: 13px;
    color: #fff;
    border: 1px solid #fff;
    padding: 12px 25px;
    display: inline-block;
    font-weight: 500;
    transition: all .3s;
}

.main-border-button a:hover {
    background-color: #fff;
    color: #2a2a2a;
}

.main-white-button a {
    font-size: 13px;
    color: #2a2a2a;
    background-color: #fff;
    padding: 12px 25px;
    display: inline-block;
    border-radius: 3px;
    font-weight: 600;
    transition: all .3s;
}

.main-white-button a:hover {
    opacity: 0.9;
}

.main-text-button a {
    font-size: 13px;
    color: #fff;
    font-weight: 600;
    transition: all .3s;
}

.main-text-button a:hover {
    opacity: 0.9;
}

.section-heading h2 {
    font-size: 34px;
    font-weight: 700;
    color: #2a2a2a;
}

.section-heading span {
    font-size: 14px;
    color: #a1a1a1;
    font-style: italic;
    font-weight: 400;
}



/* 
---------------------------------------------
header
--------------------------------------------- 
*/

.background-header {
    border-bottom: none !important;
    background-color: #fff;
    height: 80px !important;
    position: fixed !important;
    top: 0px;
    left: 0px;
    right: 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15) !important;
}

.background-header .logo,
.background-header .main-nav .nav li a {
    color: #1e1e1e !important;
}

.background-header .main-nav .nav li:hover a {
    color: #2a2a2a !important;
}

.background-header .nav li a.active {
    color: #2a2a2a !important;
}

.header-area {
    border-bottom: 3px dotted #eee;
    box-shadow: none;
    background-color: #fff;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 100;
    height: 100px;
    -webkit-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
}

.header-area .main-nav {
    min-height: 80px;
    background: transparent;
}

.header-area .main-nav .logo {
    line-height: 100px;
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    float: left;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.background-header .main-nav .logo {
    line-height: 75px;
}

.background-header .nav {
    margin-top: 20px !important;
}

.header-area .main-nav .nav {
    float: right;
    margin-top: 30px;
    margin-right: 0px;
    background-color: transparent;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    position: relative;
    z-index: 999;
}

.header-area .main-nav .nav li {
    padding-left: 15px;
    padding-right: 15px;
}

.header-area .main-nav .nav li:last-child {
    padding-right: 0px;
}

.header-area .main-nav .nav li a {
    display: block;
    font-weight: 600;
    font-size: 15px;
    color: #2a2a2a;
    text-transform: capitalize;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    height: 40px;
    line-height: 40px;
    border: transparent;
    letter-spacing: 1px;
}

.header-area .main-nav .nav li a {
    color: #2a2a2a;
}

.header-area .main-nav .nav li:hover a,
.header-area .main-nav .nav li a.active {
    color: #aaa !important;
}

.background-header .main-nav .nav li:hover a,
.background-header .main-nav .nav li a.active {
    color: #aaa !important;
    opacity: 1;
}

.header-area .main-nav .nav li.submenu {
    position: relative;
    padding-right: 30px;
}

.header-area .main-nav .nav li.submenu:after {
    font-family: FontAwesome;
    content: "\f107";
    font-size: 12px;
    color: #2a2a2a;
    position: absolute;
    right: 18px;
    top: 12px;
}

.background-header .main-nav .nav li.submenu:after {
    color: #2a2a2a;
}

.header-area .main-nav .nav li.submenu ul {
    position: absolute;
    width: 200px;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    overflow: hidden;
    top: 50px;
    opacity: 0;
    transform: translateY(+2em);
    visibility: hidden;
    z-index: -1;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
}

.header-area .main-nav .nav li.submenu ul li {
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.header-area .main-nav .nav li.submenu ul li a {
    opacity: 1;
    display: block;
    background: #f7f7f7;
    color: #2a2a2a !important;
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    position: relative;
    font-size: 13px;
    font-weight: 400;
    border-bottom: 1px solid #eee;
}

.header-area .main-nav .nav li.submenu ul li a:hover {
    background: #fff;
    color: #aaa !important;
    padding-left: 25px;
}

.header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 3px;
}

.header-area .main-nav .nav li.submenu:hover ul {
    visibility: visible;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
}

.header-area .main-nav .menu-trigger {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 33px;
    width: 32px;
    height: 40px;
    text-indent: -9999em;
    z-index: 99;
    right: 40px;
    display: none;
}

.background-header .main-nav .menu-trigger {
    top: 23px;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    background-color: #1e1e1e;
    display: block;
    position: absolute;
    width: 30px;
    height: 2px;
    left: 0;
}

.background-header .main-nav .menu-trigger span,
.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
    background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    background-color: #1e1e1e;
    display: block;
    position: absolute;
    width: 30px;
    height: 2px;
    left: 0;
    width: 75%;
}

.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
    background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
    content: "";
}

.header-area .main-nav .menu-trigger span {
    top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
    -moz-transform-origin: 33% 100%;
    -ms-transform-origin: 33% 100%;
    -webkit-transform-origin: 33% 100%;
    transform-origin: 33% 100%;
    top: -10px;
    z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
    -moz-transform-origin: 33% 0;
    -ms-transform-origin: 33% 0;
    -webkit-transform-origin: 33% 0;
    transform-origin: 33% 0;
    top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
    background-color: transparent;
    width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
    -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
    -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
    -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
    transform: translateY(6px) translateX(1px) rotate(45deg);
    background-color: #1e1e1e;
}

.background-header .main-nav .menu-trigger.active span:before {
    background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger.active span:after {
    -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
    -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
    -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
    transform: translateY(-6px) translateX(1px) rotate(-45deg);
    background-color: #1e1e1e;
}

.background-header .main-nav .menu-trigger.active span:after {
    background-color: #1e1e1e;
}

.header-area.header-sticky {
    min-height: 80px;
}

.header-area .nav {
    margin-top: 30px;
}

.header-area.header-sticky .nav li a.active {
    color: #aaa;
}

@media (max-width: 1200px) {
    .header-area .main-nav .nav li {
        padding-left: 12px;
        padding-right: 12px;
    }

    .header-area .main-nav:before {
        display: none;
    }
}

@media (max-width: 767px) {
    .header-area .main-nav .logo {
        color: #1e1e1e;
    }

    .header-area.header-sticky .nav li a:hover,
    .header-area.header-sticky .nav li a.active {
        color: #aaa !important;
        opacity: 1;
    }

    .header-area.header-sticky .nav li.search-icon a {
        width: 100%;
    }

    .header-area {
        background-color: #f7f7f7;
        padding: 0px 15px;
        height: 100px;
        box-shadow: none;
        text-align: center;
    }

    .header-area .container {
        padding: 0px;
    }

    .header-area .logo {
        margin-left: 30px;
    }

    .header-area .menu-trigger {
        display: block !important;
    }

    .header-area .main-nav {
        overflow: hidden;
    }

    .header-area .main-nav .nav {
        float: none;
        width: 100%;
        display: none;
        -webkit-transition: all 0s ease 0s;
        -moz-transition: all 0s ease 0s;
        -o-transition: all 0s ease 0s;
        transition: all 0s ease 0s;
        margin-left: 0px;
    }

    .header-area .main-nav .nav li:first-child {
        border-top: 1px solid #eee;
    }

    .header-area.header-sticky .nav {
        margin-top: 100px !important;
    }

    .header-area .main-nav .nav li {
        width: 100%;
        background: #fff;
        border-bottom: 1px solid #eee;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .header-area .main-nav .nav li a {
        height: 50px !important;
        line-height: 50px !important;
        padding: 0px !important;
        border: none !important;
        background: #f7f7f7 !important;
        color: #191a20 !important;
    }

    .header-area .main-nav .nav li a:hover {
        background: #eee !important;
        color: #aaa !important;
    }

    .header-area .main-nav .nav li.submenu ul {
        position: relative;
        visibility: inherit;
        opacity: 1;
        z-index: 1;
        transform: translateY(0%);
        transition-delay: 0s, 0s, 0.3s;
        top: 0px;
        width: 100%;
        box-shadow: none;
        height: 0px;
    }

    .header-area .main-nav .nav li.submenu ul li a {
        font-size: 12px;
        font-weight: 400;
    }

    .header-area .main-nav .nav li.submenu ul li a:hover:before {
        width: 0px;
    }

    .header-area .main-nav .nav li.submenu ul.active {
        height: auto !important;
    }

    .header-area .main-nav .nav li.submenu:after {
        color: #3B566E;
        right: 25px;
        font-size: 14px;
        top: 15px;
    }

    .header-area .main-nav .nav li.submenu:hover ul,
    .header-area .main-nav .nav li.submenu:focus ul {
        height: 0px;
    }
}

@media (min-width: 767px) {
    .header-area .main-nav .nav {
        display: flex !important;
    }
}


/* 
---------------------------------------------
banner
--------------------------------------------- 
*/

.main-banner {}

.main-banner .left-content .thumb img {
    width: 100%;
    overflow: hidden;
}

.main-banner .left-content .inner-content {
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);
}

.main-banner .left-content .inner-content h4 {
    color: #fff;
    margin-top: -10px;
    font-size: 52px;
    font-weight: 700;
    margin-bottom: 20px;
}

.main-banner .left-content .inner-content span {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    font-style: italic;
    display: block;
    margin-bottom: 30px;
}

.main-banner .right-content .right-first-image {
    margin-bottom: 28.5px;
}

.main-banner .right-content .right-first-image .thumb {
    position: relative;
    text-align: center;
}

.main-banner .right-content .right-first-image .thumb img {
    width: 100%;
    overflow: hidden;
}

.main-banner .right-content .right-first-image .thumb .inner-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
}

.main-banner .right-content .right-first-image .thumb .inner-content h4 {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
}

.main-banner .right-content .right-first-image .thumb .inner-content span {
    font-size: 16px;
    color: #fff;
    font-style: italic;
}

.main-banner .right-content .right-first-image .thumb .hover-content {
    position: absolute;
    top: 15px;
    right: 15px;
    left: 15px;
    bottom: 15px;
    text-align: center;
    background-color: rgba(42, 42, 42, .97);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
}

.main-banner .right-content .right-first-image .thumb:hover .hover-content {
    opacity: 1;
    visibility: visible;
}

.main-banner .right-content .right-first-image .thumb .hover-content .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.main-banner .right-content .right-first-image .thumb .hover-content h4 {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
}

.main-banner .right-content .right-first-image .thumb .hover-content p {
    color: #fff;
    padding: 0px 20px;
    margin-bottom: 20px;
}



/*
---------------------------------------------
Men
---------------------------------------------
*/

#saree {
    padding-top: 90px;
    padding-bottom: 90px;
    border-bottom: 3px dotted #eee;
}

#saree .section-heading {
    margin-bottom: 60px;
}

#saree .item .down-content {
    padding-top: 30px;
    position: relative;
    z-index: 3;
    background-color: #fff;
}

#saree .item .down-content h4 {
    font-size: 22px;
    color: #2a2a2a;
    font-weight: 700;
    margin-bottom: 8px;
}

#saree .item .down-content span {
    font-size: 18px;
    color: #a1a1a1;
    font-weight: 500;
    display: block;
}

#saree .item .down-content ul.stars {
    position: absolute;
    right: 0;
    top: 30px;
}

#saree .item .down-content ul.stars li {
    display: inline;
    font-size: 13px;
}

#saree .item .thumb .hover-content {
    position: absolute;
    z-index: 2;
    text-align: center;
    bottom: -60px;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .5s;
}

#saree .item .thumb:hover .hover-content {
    bottom: 30px;
    opacity: 1;
    visibility: visible;
}

#saree .item .thumb {
    position: relative;
}

#saree .item .thumb .hover-content ul li {
    display: inline;
    margin: 0px 10px;
}

#saree .item .thumb .hover-content ul li a {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    display: inline-block;
    color: #2a2a2a;
    background-color: #fff;
}


#saree .owl-nav {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 40%;
    transform: translateY(-25px);
}

#saree .owl-dots {
    display: none;
}

#saree .owl-nav .owl-prev {
    position: absolute;
    left: -80px;
    outline: none;
}

#saree .owl-nav .owl-prev span,
#saree .owl-nav .owl-next span {
    opacity: 0;
}

#saree .owl-nav .owl-prev:before {
    display: inline-block;
    font-family: 'FontAwesome';
    color: #2a2a2a;
    font-size: 25px;
    font-weight: 700;
    content: '\f104';
    width: 50px;
    height: 50px;
    background-color: transparent;
    line-height: 48px;
    border: 1px solid #2a2a2a;
}

#saree .owl-nav .owl-prev {
    opacity: 0.75;
    transition: all .5s;
}

#saree .owl-nav .owl-prev:hover {
    opacity: 1;
}

#saree .owl-nav .owl-next {
    opacity: 0.75;
    transition: all .5s;
}

#saree .owl-nav .owl-next:hover {
    opacity: 1;
}

#saree .owl-nav .owl-next {
    outline: none;
    position: absolute;
    right: -85px;
}

#saree .owl-nav .owl-next:before {
    display: inline-block;
    font-family: 'FontAwesome';
    color: #2a2a2a;
    font-size: 25px;
    font-weight: 700;
    content: '\f105';
    width: 50px;
    height: 50px;
    background-color: transparent;
    line-height: 48px;
    border: 1px solid #2a2a2a;
}


/*
---------------------------------------------
Women
---------------------------------------------
*/

#kurti {
    padding-top: 90px;
    padding-bottom: 90px;
    border-bottom: 3px dotted #eee;
}

#kurti .section-heading {
    margin-bottom: 60px;
}

#kurti .item .down-content {
    padding-top: 30px;
    position: relative;
    z-index: 3;
    background-color: #fff;
}

#kurti .item .down-content h4 {
    font-size: 22px;
    color: #2a2a2a;
    font-weight: 700;
    margin-bottom: 8px;
}

#kurti .item .down-content span {
    font-size: 18px;
    color: #a1a1a1;
    font-weight: 500;
    display: block;
}

#kurti .item .down-content ul.stars {
    position: absolute;
    right: 0;
    top: 30px;
}

#kurti .item .down-content ul.stars li {
    display: inline;
    font-size: 13px;
}

#kurti .item .thumb .hover-content {
    position: absolute;
    z-index: 2;
    text-align: center;
    bottom: -60px;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .5s;
}

#kurti .item .thumb:hover .hover-content {
    bottom: 30px;
    opacity: 1;
    visibility: visible;
}

#kurti .item .thumb {
    position: relative;
}

#kurti .item .thumb .hover-content ul li {
    display: inline;
    margin: 0px 10px;
}

#kurti .item .thumb .hover-content ul li a {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    display: inline-block;
    color: #2a2a2a;
    background-color: #fff;
}


#kurti .owl-nav {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 40%;
    transform: translateY(-25px);
}

#kurti .owl-dots {
    display: none;
}

#kurti .owl-nav .owl-prev {
    position: absolute;
    left: -80px;
    outline: none;
}

#kurti .owl-nav .owl-prev span,
#kurti .owl-nav .owl-next span {
    opacity: 0;
}

#kurti .owl-nav .owl-prev:before {
    display: inline-block;
    font-family: 'FontAwesome';
    color: #2a2a2a;
    font-size: 25px;
    font-weight: 700;
    content: '\f104';
    width: 50px;
    height: 50px;
    background-color: transparent;
    line-height: 48px;
    border: 1px solid #2a2a2a;
}

#kurti .owl-nav .owl-prev {
    opacity: 0.75;
    transition: all .5s;
}

#kurti .owl-nav .owl-prev:hover {
    opacity: 1;
}

#kurti .owl-nav .owl-next {
    opacity: 0.75;
    transition: all .5s;
}

#kurti .owl-nav .owl-next:hover {
    opacity: 1;
}

#kurti .owl-nav .owl-next {
    outline: none;
    position: absolute;
    right: -85px;
}

#kurti .owl-nav .owl-next:before {
    display: inline-block;
    font-family: 'FontAwesome';
    color: #2a2a2a;
    font-size: 25px;
    font-weight: 700;
    content: '\f105';
    width: 50px;
    height: 50px;
    background-color: transparent;
    line-height: 48px;
    border: 1px solid #2a2a2a;
}

/*
---------------------------------------------
Kids
---------------------------------------------
*/

#kids {
    padding-top: 90px;
    padding-bottom: 90px;
    border-bottom: 3px dotted #eee;
}

#kids .section-heading {
    margin-bottom: 60px;
}

#kids .item .down-content {
    padding-top: 30px;
    position: relative;
    z-index: 3;
    background-color: #fff;
}

#kids .item .down-content h4 {
    font-size: 22px;
    color: #2a2a2a;
    font-weight: 700;
    margin-bottom: 8px;
}

#kids .item .down-content span {
    font-size: 18px;
    color: #a1a1a1;
    font-weight: 500;
    display: block;
}

#kids .item .down-content ul.stars {
    position: absolute;
    right: 0;
    top: 30px;
}

#kids .item .down-content ul.stars li {
    display: inline;
    font-size: 13px;
}

#kids .item .thumb .hover-content {
    position: absolute;
    z-index: 2;
    text-align: center;
    bottom: -60px;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .5s;
}

#kids .item .thumb:hover .hover-content {
    bottom: 30px;
    opacity: 1;
    visibility: visible;
}

#kids .item .thumb {
    position: relative;
}

#kids .item .thumb .hover-content ul li {
    display: inline;
    margin: 0px 10px;
}

#kids .item .thumb .hover-content ul li a {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    display: inline-block;
    color: #2a2a2a;
    background-color: #fff;
}


#kids .owl-nav {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 40%;
    transform: translateY(-25px);
}

#kids .owl-dots {
    display: none;
}

#kids .owl-nav .owl-prev {
    position: absolute;
    left: -80px;
    outline: none;
}

#kids .owl-nav .owl-prev span,
#kids .owl-nav .owl-next span {
    opacity: 0;
}

#kids .owl-nav .owl-prev:before {
    display: inline-block;
    font-family: 'FontAwesome';
    color: #2a2a2a;
    font-size: 25px;
    font-weight: 700;
    content: '\f104';
    width: 50px;
    height: 50px;
    background-color: transparent;
    line-height: 48px;
    border: 1px solid #2a2a2a;
}

#kids .owl-nav .owl-prev {
    opacity: 0.75;
    transition: all .5s;
}

#kids .owl-nav .owl-prev:hover {
    opacity: 1;
}

#kids .owl-nav .owl-next {
    opacity: 0.75;
    transition: all .5s;
}

#kids .owl-nav .owl-next:hover {
    opacity: 1;
}

#kids .owl-nav .owl-next {
    outline: none;
    position: absolute;
    right: -85px;
}

#kids .owl-nav .owl-next:before {
    display: inline-block;
    font-family: 'FontAwesome';
    color: #2a2a2a;
    font-size: 25px;
    font-weight: 700;
    content: '\f105';
    width: 50px;
    height: 50px;
    background-color: transparent;
    line-height: 48px;
    border: 1px solid #2a2a2a;
}


/*
---------------------------------------------
Explore
---------------------------------------------
*/

#explore {
    padding-top: 90px;
    padding-bottom: 90px;
    border-bottom: 3px dotted #eee;
}

#explore .left-content {
    margin-right: 30px;
}

#explore .left-content h2 {
    font-size: 34px;
    font-weight: 700;
    color: #2a2a2a;
}

.left-content h2 {
    font-size: 34px;
    font-weight: 700;
    color: #2a2a2a;
}

#explore .left-content span {
    font-size: 14px;
    color: #aaaaaa;
    font-weight: 500;
    display: block;
    margin-top: 25px;
}

#explore .left-content .quote {
    margin-top: 25px;
}

#explore .left-content .quote i {
    float: left;
    font-size: 32px;
    margin-right: 15px;
    margin-top: 10px;
}

#explore .left-content .quote p {
    font-style: italic;
    color: #2a2a2a;
    font-weight: 500;
}

#explore .left-content p {
    font-size: 14px;
    color: #aaaaaa;
    font-weight: 500;
    display: block;
    margin-top: 25px;
}

#explore .left-content .main-border-button a {
    color: #2a2a2a;
    border-color: #2a2a2a;
    margin-top: 35px;
}

#explore .left-content .main-border-button a:hover {
    background-color: #2a2a2a;
    color: #fff;
}

#explore img {
    width: 100%;
    overflow: hidden;
}

#explore .leather {
    margin-bottom: 30px;
}

#explore .first-image {
    margin-bottom: 30px;
}

#explore .leather,
#explore .types {
    min-height: 255px;
    background-color: #f8f8f8;
    text-align: center;
    width: 100%;
}

#explore .leather h4,
#explore .types h4 {
    font-size: 24px;
    font-weight: 700;
    color: #2a2a2a;
    padding-top: 90px;
    margin-bottom: 5px;
}

#explore .leather span,
#explore .types span {
    font-size: 14px;
    font-style: italic;
    color: #aaa;
    font-weight: 500;
}

/* 
---------------------------------------------
Social
--------------------------------------------- 
*/

#social {
    margin-top: 90px;
    padding-bottom: 90px;
    border-bottom: 3px dotted #eee;
}

#social .col-2 {
    padding-left: 0;
    padding-right: 0;
}

#social .images {
    margin-top: 60px;
    padding-left: 15px;
    padding-right: 15px;
}

#social .thumb {
    position: relative;
}

#social .thumb .icon {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
}

#social .thumb:hover .icon {
    opacity: 1;
    visibility: visible;
}

#social .thumb .icon h6 {
    color: #fff;
    position: absolute;
    font-size: 14px;
    bottom: 40px;
    font-weight: 400;
    left: 15px;
}

#social .thumb .icon i {
    color: #fff;
    position: absolute;
    bottom: 15px;
    left: 15px;
}

#social .thumb img {
    width: 100%;
    overflow: hidden;
}


/* 
---------------------------------------------
Subscribe
--------------------------------------------- 
*/

.subscribe {
    margin-top: 90px;
}

.subscribe .section-heading {
    margin-bottom: 30px;
}

.subscribe .section-heading h2 {
    line-height: 55px;
}

.subscribe form input {
    width: 100%;
    height: 44px;
    line-height: 44px;
    padding: 0px 15px;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    color: #aaa;
    border-radius: 0px;
    border: 1px solid #7a7a7a;
    box-shadow: none;
}

.subscribe form input::focus {
    border-color: #2a2a2a;
    border-radius: 0px;
}

.subscribe form button {
    width: 44px;
    height: 44px;
    display: inline-block;
    text-align: center;
    line-height: 44px;
    background-color: #2a2a2a;
    box-shadow: none;
    border: 1px solid transparent;
    color: #fff;
    transition: all 0.3s;
}

.subscribe form button:hover {
    background-color: #fff;
    border: 1px solid #2a2a2a;
    color: #2a2a2a;
}

.subscribe ul li {
    margin-bottom: 30px;
    display: block;
}

.subscribe ul li {
    font-size: 16px;
    font-weight: 700;
    color: #2a2a2a;
}

.subscribe ul li span,
.subscribe ul li span a {
    font-size: 14px;
    font-weight: 400;
    color: #aaa;
}

.subscribe ul li span a:hover {
    color: #2a2a2a;
}


/* 
---------------------------------------------
footer
--------------------------------------------- 
*/

footer {
    margin-top: 60px;
    padding: 60px 0px 30px 0px;
    background-color: #2a2a2a;
}

footer .logo {
    margin-bottom: 20px;
}

footer ul li {
    display: block;
    margin-bottom: 10px;
}

footer ul li a {
    font-size: 14px;
    color: #fff;
    transition: all .3s;
}

footer ul li a:hover {
    color: #aaa;
}

footer h4 {
    letter-spacing: 0.5px;
    font-size: 16px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
}

footer .under-footer {
    margin-top: 30px;
    padding-top: 30px;
    text-align: center;
    border-top: 1px solid rgba(250, 250, 250, 0.3);
}

footer .under-footer ul {
    margin-top: 20px;
}

footer .under-footer ul li {
    display: inline-block;
    margin: 0px 10px;
}

footer .under-footer ul li a {
    font-size: 22px;
}

footer .under-footer p {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
}

footer .under-footer a {
    color: #cff;
}

footer .under-footer a:hover {
    color: #c66;
}


/* 
---------------------------------------------
Products Page
--------------------------------------------- 
*/

.page-heading {
    margin-top: 160px;
    margin-bottom: 30px;
    background-image: url(./images/products-page-heading.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.page-heading .inner-content {
    padding: 150px 0px;
    text-align: center;
}

.page-heading .inner-content h2 {
    font-size: 52px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 15px;
}

.page-heading .inner-content span {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    font-style: italic;
}

#products .section-heading {
    text-align: center;
    margin-bottom: 60px;
    margin-top: 80px;
}

#products .item {
    margin-bottom: 30px;
}

#products .item .thumb img {
    width: 100%;
    overflow: hidden;
}

#products .item .down-content {
    padding-top: 20px;
    position: relative;
    z-index: 3;
    background-color: #fff;
}

#products .item .down-content h4 {
    font-size: 22px;
    color: #2a2a2a;
    font-weight: 700;
    margin-bottom: 8px;
}

#products .item .down-content span {
    font-size: 18px;
    color: #a1a1a1;
    font-weight: 500;
    display: block;
}

#products .item .down-content ul.stars {
    position: absolute;
    right: 0;
    top: 20px;
}

#products .item .down-content ul.stars li {
    display: inline;
    font-size: 13px;
}

#products .item .thumb .hover-content {
    position: absolute;
    z-index: 2;
    text-align: center;
    bottom: -60px;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .5s;
}

#products .item .thumb:hover .hover-content {
    bottom: 30px;
    opacity: 1;
    visibility: visible;
}

#products .item .thumb {
    position: relative;
}

#products .item .thumb .hover-content ul li {
    display: inline;
    margin: 0px 10px;
}

#products .item .thumb .hover-content ul li a {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    display: inline-block;
    color: #2a2a2a;
    background-color: #fff;
}

#products .pagination {
    margin-top: 30px;
    margin-bottom: 40px;
}

#products .pagination ul {
    text-align: center;
    width: 100%;
}

#products .pagination ul li {
    display: inline;
    margin: 0px 5px;
}

#products .pagination ul li a {
    width: 44px;
    height: 44px;
    border: 1px solid #2a2a2a;
    display: inline-block;
    text-align: center;
    line-height: 44px;
    font-size: 14px;
    font-weight: 500;
    color: #2a2a2a;
}

#products .pagination ul li a:hover,
#products .pagination ul li.active a {
    background-color: #2a2a2a;
    color: #fff;
}



/* 
---------------------------------------------
Single Product Page
--------------------------------------------- 
*/

#product {
    margin-top: 80px;
}

#product .left-images img {
    width: 100%;
    overflow: hidden;
    margin-bottom: 30px;
}

#product .right-content {
    position: relative;
    z-index: 3;
    background-color: #fff;
}

#product .right-content h4 {
    font-size: 22px;
    color: #2a2a2a;
    font-weight: 700;
    margin-bottom: 0px;
}

#product .right-content span.price {
    font-size: 18px;
    color: #a1a1a1;
    font-weight: 500;
    display: block;
    margin-top: 5px;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
}

#product .right-content ul.stars {
    position: absolute;
    right: 0;
    top: 0;
}

#product .right-content ul.stars li {
    display: inline;
    font-size: 13px;
}

#product .right-content span {
    font-size: 14px;
    color: #aaaaaa;
    font-weight: 500;
    display: block;
    margin-top: 25px;
}

#product .right-content .quote {
    margin-top: 25px;
}

#product .right-content .quote i {
    float: left;
    font-size: 32px;
    margin-right: 15px;
    margin-top: 10px;
}

#product .right-content .quote p {
    font-style: italic;
    color: #2a2a2a;
    font-weight: 500;
}

#product .right-content p {
    font-size: 14px;
    color: #aaaaaa;
    font-weight: 500;
    display: block;
    margin-top: 25px;
}

.quantity-content {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.quantity-content .left-content {
    float: left;
    margin-right: 30px;
}

.quantity-content .right-content {
    text-align: right;
}

.quantity-content .left-content h6 {
    padding-top: 7px;
    font-size: 20px;
    font-weight: 700;
    color: #aaa;
    position: relative;
    z-index: 11;
}

.total h4 {
    float: left;
    font-size: 20px;
    margin-top: 10px;
    color: #aaa !important;
}

.total .main-border-button {
    text-align: right;
}

.total .main-border-button a {
    border-color: #2a2a2a;
    color: #2a2a2a;
}

.total .main-border-button a:hover {
    color: #fff;
    background-color: #2a2a2a;
}

.quantity {
    display: inline-block;
}

.quantity .input-text.qty {
    width: 35px;
    height: 39px;
    padding: 0 5px;
    text-align: center;
    background-color: transparent;
    border: 1px solid #efefef;
}

.quantity.buttons_added {
    text-align: left;
    position: relative;
    white-space: nowrap;
    vertical-align: top;
}

.quantity.buttons_added input {
    display: inline-block;
    margin: 0;
    vertical-align: top;
    box-shadow: none;
}

.quantity.buttons_added .minus,
.quantity.buttons_added .plus {
    padding: 7px 10px 8px;
    height: 39px;
    background-color: #ffffff;
    border: 1px solid #efefef;
    cursor: pointer;
}

.quantity.buttons_added .minus {
    border-right: 0;
}

.quantity.buttons_added .plus {
    border-left: 0;
}

.quantity.buttons_added .minus:hover,
.quantity.buttons_added .plus:hover {
    background: #eeeeee;
}

.quantity input::-webkit-outer-spin-button,
.quantity input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
}

.quantity.buttons_added .minus:focus,
.quantity.buttons_added .plus:focus {
    outline: none;
}



/* 
---------------------------------------------
About Us Page
--------------------------------------------- 
*/

.about-page-heading {
    margin-top: 160px;
    margin-bottom: 30px;
    background-image: url(./images/about-us-page-heading.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.about-us {
    margin-top: 80px;
}

.about-us .left-image img {
    width: 100%;
    overflow: hidden;
}

.about-us .right-content {
    margin-left: 30px;
}

.about-us .right-content h4 {
    font-size: 34px;
    font-weight: 700;
    color: #2a2a2a;
}

.about-us .right-content span {
    font-size: 14px;
    color: #aaaaaa;
    font-weight: 500;
    display: block;
    margin-top: 25px;
}

.about-us .right-content .quote {
    margin-top: 25px;
}

.about-us .right-content .quote i {
    float: left;
    font-size: 32px;
    margin-right: 15px;
    margin-top: 10px;
}

.about-us .right-content .quote p {
    font-style: italic;
    color: #2a2a2a;
    font-weight: 500;
}

.about-us .right-content p {
    font-size: 14px;
    color: #aaaaaa;
    font-weight: 500;
    display: block;
    margin-top: 25px;
}

.about-us .right-content ul {
    border-top: 3px dotted #eee;
    margin-top: 30px;
    padding-top: 30px;
}

.about-us .right-content ul li {
    display: inline-block;
    margin-right: 5px;
}

.about-us .right-content ul li a {
    width: 44px;
    height: 44px;
    background-color: #2a2a2a;
    color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 44px;
    border-radius: 50%;
    transition: all .5s;
}

.about-us .right-content ul li a:hover {
    background-color: #eee;
    color: #2a2a2a;
}

.our-team {
    margin-top: 100px;
    padding-top: 90px;
    padding-bottom: 90px;
    border-top: 3px dotted #eee;
}

.our-team .section-heading {
    margin-bottom: 60px;
    text-align: center;
}

.our-team .section-heading h2 {
    margin-bottom: 10px;
}

.our-team .team-item .thumb {
    position: relative;
}

.our-team .team-item .thumb img {
    width: 100%;
    overflow: hidden;
}

.our-team .team-item .thumb .hover-effect {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    text-align: center;
    top: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
}

.our-team .team-item .thumb:hover .hover-effect {
    opacity: 1;
    visibility: visible;
}

.our-team .team-item .thumb .hover-effect .inner-content {
    position: absolute;
    top: 50%;
    text-align: center;
    width: 100%;
    transform: translateY(-22px);
}

.our-team .team-item .thumb .hover-effect .inner-content ul li {
    display: inline-block;
    margin: 0px 5px;
}

.our-team .team-item .thumb .hover-effect .inner-content ul li a {
    width: 44px;
    height: 44px;
    background-color: #fff;
    color: #2a2a2a;
    display: inline-block;
    text-align: center;
    line-height: 44px;
    border-radius: 50%;
    transition: all .3s;
}

.our-team .team-item .thumb .hover-effect .inner-content ul li a:hover {
    background-color: #2a2a2a;
    color: #fff;
}

.our-team .team-item .down-content {
    text-align: center;
    padding-top: 25px;
}

.our-team .team-item .down-content h4 {
    font-size: 24px;
    color: #2a2a2a;
    font-weight: 700;
    margin-bottom: 5px;
}

.our-team .team-item .down-content span {
    font-size: 14px;
    color: #a1a1a1;
    font-weight: 500;
    display: block;
    font-style: italic;
}

.our-services {
    padding-top: 90px;
    padding-bottom: 90px;
    border-top: 3px dotted #eee;
    border-bottom: 3px dotted #eee;
}

.our-services .section-heading {
    margin-bottom: 60px;
    text-align: center;
}

.our-services .section-heading h2 {
    margin-bottom: 10px;
}

.our-services .service-item {
    padding: 30px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
    text-align: center;
}

.our-services .service-item img {
    width: 100%;
    overflow: hidden;
}

.our-services .service-item h4 {
    font-size: 24px;
    font-weight: 700;
    color: #2a2a2a;
    margin-bottom: 20px;
}

.our-services .service-item p {
    margin-bottom: 25px;
}



/* 
---------------------------------------------
contact Us Page
--------------------------------------------- 
*/

.contact-us {
    margin-top: 90px;
    border-bottom: 3px dotted #eee;
    padding-bottom: 90px;
}

.contact-us .section-heading {
    margin-bottom: 40px;
}

.contact-us #map {
    margin-right: 30px;
}

.contact-us form input {
    width: 100%;
    height: 44px;
    line-height: 44px;
    padding: 0px 15px;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    color: #aaa;
    border-radius: 0px;
    border: 1px solid #7a7a7a;
    box-shadow: none;
}

.contact-us form input::focus {
    border-color: #2a2a2a;
    border-radius: 0px;
}

.contact-us form textarea {
    width: 100%;
    height: 140px;
    min-height: 120px;
    max-height: 200px;
    margin-top: 30px;
    margin-bottom: 25px;
    line-height: 44px;
    padding: 0px 15px;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    color: #aaa;
    border-radius: 0px;
    border: 1px solid #7a7a7a;
    box-shadow: none;
}

.contact-us form textarea::focus {
    border-color: #2a2a2a;
    border-radius: 0px;
}

.contact-us form button {
    width: 44px;
    height: 44px;
    display: inline-block;
    text-align: center;
    line-height: 44px;
    background-color: #2a2a2a;
    box-shadow: none;
    border: 1px solid transparent;
    color: #fff;
    transition: all 0.3s;
}

.contact-us form button:hover {
    background-color: #fff;
    border: 1px solid #2a2a2a;
    color: #2a2a2a;
}


/* 
---------------------------------------------
responsive
--------------------------------------------- 
*/

@media (max-width: 1200px) {
    .main-banner .right-content .right-first-image .thumb .hover-content p {
        display: none;
    }
}

@media (max-width: 992px) {
    .main-banner .right-content .right-first-image .thumb .hover-content p {
        display: block;
    }

    .header-area .main-nav .nav li.submenu:after {
        right: 3px;
    }

    .header-area .main-nav .nav li.submenu {
        padding-right: 15px;
    }

    .header-area .main-nav .nav li {
        padding-right: 5px;
        padding-left: 5px;
    }

    .header-area .main-nav .nav li a {
        font-size: 14px;
        letter-spacing: 0px;
    }

    .main-banner .left-content {
        margin-bottom: 30px;
    }

    #explore .left-content {
        margin-bottom: 30px;
    }

    #explore .right-content .types {
        margin-top: 30px;
    }

    .subscribe input {
        margin-bottom: 15px;
    }

    .subscribe form {
        text-align: center;
        margin-bottom: 30px;
    }

    .about-us .right-content {
        margin-left: 0px;
        margin-top: 30px;
    }

    .our-team .team-item {
        margin-bottom: 30px;
    }

    .our-services .service-item {
        margin-bottom: 30px;
    }

    .contact-us #map {
        margin-right: 0px;
        margin-bottom: 30px;
    }
}

@media (max-width: 767px) {
    .main-banner .left-content .thumb .inner-content {
        left: 50%;
        top: 47.5%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .subscribe form button {
        width: 100%;
    }

    footer {
        text-align: center;
    }

    footer h4 {
        margin-top: 30px;
    }
}





#preloader {
    overflow: hidden;
    background-color: #2a2a2a;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 99999;
    color: #fff;
}

#preloader .jumper {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    margin: auto;
    width: 50px;
    height: 50px;
}

#preloader .jumper>div {
    background-color: #fff;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    opacity: 0;
    width: 50px;
    height: 50px;
    -webkit-animation: jumper 1s 0s linear infinite;
    animation: jumper 1s 0s linear infinite;
}

#preloader .jumper>div:nth-child(2) {
    -webkit-animation-delay: 0.33333s;
    animation-delay: 0.33333s;
}

#preloader .jumper>div:nth-child(3) {
    -webkit-animation-delay: 0.66666s;
    animation-delay: 0.66666s;
}

@-webkit-keyframes jumper {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    5% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes jumper {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    5% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}