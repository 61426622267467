.homeBanner {
  width: 100%;
  min-height: 700px;
  background-color: skyblue;
}

.text-justify {
  text-align: justify;
}

.logo {
  width: 80px;
}